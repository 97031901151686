// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-client-testimonials-js": () => import("./../../../src/pages/about/client-testimonials.js" /* webpackChunkName: "component---src-pages-about-client-testimonials-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-meet-the-team-js": () => import("./../../../src/pages/about/meet-the-team.js" /* webpackChunkName: "component---src-pages-about-meet-the-team-js" */),
  "component---src-pages-aesthetic-treatments-bournemouth-and-poole-js": () => import("./../../../src/pages/aesthetic-treatments-bournemouth-and-poole.js" /* webpackChunkName: "component---src-pages-aesthetic-treatments-bournemouth-and-poole-js" */),
  "component---src-pages-beauty-treatments-bournemouth-and-poole-js": () => import("./../../../src/pages/beauty-treatments-bournemouth-and-poole.js" /* webpackChunkName: "component---src-pages-beauty-treatments-bournemouth-and-poole-js" */),
  "component---src-pages-black-friyay-js": () => import("./../../../src/pages/black-friyay.js" /* webpackChunkName: "component---src-pages-black-friyay-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-christmas-promotions-js": () => import("./../../../src/pages/christmas-promotions.js" /* webpackChunkName: "component---src-pages-christmas-promotions-js" */),
  "component---src-pages-conditions-js": () => import("./../../../src/pages/conditions.js" /* webpackChunkName: "component---src-pages-conditions-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-feedback-form-js": () => import("./../../../src/pages/feedback-form.js" /* webpackChunkName: "component---src-pages-feedback-form-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-health-treatments-bournemouth-and-poole-js": () => import("./../../../src/pages/health-treatments-bournemouth-and-poole.js" /* webpackChunkName: "component---src-pages-health-treatments-bournemouth-and-poole-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-skin-treatments-bournemouth-and-poole-js": () => import("./../../../src/pages/skin-treatments-bournemouth-and-poole.js" /* webpackChunkName: "component---src-pages-skin-treatments-bournemouth-and-poole-js" */),
  "component---src-pages-slim-treatments-bournemouth-and-poole-js": () => import("./../../../src/pages/slim-treatments-bournemouth-and-poole.js" /* webpackChunkName: "component---src-pages-slim-treatments-bournemouth-and-poole-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-treatment-offers-js": () => import("./../../../src/pages/treatment-offers.js" /* webpackChunkName: "component---src-pages-treatment-offers-js" */),
  "component---src-pages-treatment-prices-js": () => import("./../../../src/pages/treatment-prices.js" /* webpackChunkName: "component---src-pages-treatment-prices-js" */),
  "component---src-templates-about-pages-template-js": () => import("./../../../src/templates/aboutPagesTemplate.js" /* webpackChunkName: "component---src-templates-about-pages-template-js" */),
  "component---src-templates-aftercare-template-js": () => import("./../../../src/templates/aftercareTemplate.js" /* webpackChunkName: "component---src-templates-aftercare-template-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/blogListTemplate.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/blogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-conditions-template-js": () => import("./../../../src/templates/conditionsTemplate.js" /* webpackChunkName: "component---src-templates-conditions-template-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-team-member-js": () => import("./../../../src/templates/teamMember.js" /* webpackChunkName: "component---src-templates-team-member-js" */),
  "component---src-templates-treatments-template-js": () => import("./../../../src/templates/treatmentsTemplate.js" /* webpackChunkName: "component---src-templates-treatments-template-js" */)
}

